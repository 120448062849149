<template>
  <div>
    <v-row
      justify="end"
      align="center"
      no-gutters
      class="mb-2"
    >
      <v-col>
        <v-alert
          text
          prominent
          icon="mdi-information-outline"
          color="primary"
          class="mb-0"
        >
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" md>
              <div
                class="headline mx-5 justify-last-center"
                v-text="$t('criticalchain.defineBufferSize.message')"
              />
            </v-col>
            <v-col cols="auto">
              <v-row justify="center" align="center" no-gutters>
                <v-col cols="auto" class="px-10">
                  <v-text-field
                    v-model="internalBufferSize"
                    :suffix="$t('criticalchain.defineBufferSize.bufferSuffix')"
                    style="width: 150px"
                    hide-details
                    solo
                    type="number"
                    :min="0"
                  />
                </v-col>
                <v-col cols="12" sm="auto" class="py-2 text-center">
                  <v-btn
                    :disabled="loading"
                    :loading="loadingPreviousStep"
                    class="mx-1"
                    color="primary"
                    outlined
                    @click="previousStep"
                    v-text="$t('general.previous')"
                  />
                  <v-btn
                    :disabled="!internalBufferSize || !+internalBufferSize || loading"
                    :loading="loadingDefineBufferSize"
                    class="mx-1"
                    color="success"
                    @click="defineBufferSize"
                    v-text="$t('general.finish')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <frappe-gantt
      v-model="internalTaskList"
      select-key="isOnCriticalChain"
      fixed
    />
  </div>
</template>

<script>
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import FrappeGantt from '../components/FrappeGantt'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'DefineBufferSizeContainer',
  components: {
    FrappeGantt,
  },
  props: {
    currentPlayer: {
      type: Object,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data () {
    return {
      internalTaskList: [],
      internalBufferSize: null,
      loadingDefineBufferSize: false,
      loadingPreviousStep: false,
    }
  },
  computed: {
    loading () {
      return this.loadingDefineBufferSize || this.loadingPreviousStep
    },
  },
  watch: {
    currentPlayer: {
      immediate: true,
      handler () {
        this.internalTaskList = JSON.parse(JSON.stringify(this.currentPlayer.taskList))
        this.internalBufferSize = this.currentPlayer.bufferSize
      },
    },
    internalBufferSize (size) {
      const { start } = this.internalTaskList.find(task => task.id === 'Buffer')
      const startDate = parseISO(start)
      const endDate = this.addDays(startDate, Math.round(size / 2.5) - 1)
      const end = formatISO(endDate, { representation: 'date' })
      this.internalTaskList = this.internalTaskList
        .map(task => (task.id === 'Buffer' ? {
          ...task,
          end,
          name: `${task.id} (${+size})`,
        } : task))
    },
  },
  methods: {
    async defineBufferSize () {
      try {
        this.loadingDefineBufferSize = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.defineBufferSize, {
          taskList: this.internalTaskList,
          bufferSize: +this.internalBufferSize,
        })
      }
      finally {
        this.loadingDefineBufferSize = false
      }
    },
    async previousStep () {
      try {
        this.loadingPreviousStep = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.previousStep)
      }
      finally {
        this.loadingPreviousStep = false
      }
    },
    addDays (date, days) {
      const result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    },
  },
}
</script>
